import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const newsSummaryMenuRegistry = {
    company_newsSummary: {
        label: "News Summary",
        link: PAGE_URL.NEWS_SUMMARY_PAGE,
        key: PAGE_URL.NEWS_SUMMARY_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default newsSummaryMenuRegistry;
