import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const newsArticleMenuRegistry = {
    company_newsArticle: {
        label: "News Article",
        link: PAGE_URL.NEWS_ARTICLE_PAGE,
        key: PAGE_URL.NEWS_ARTICLE_PAGE,
        resourceKey: PERMISSION_KEY
    },
    company_newsArticleHome: {
        label: "News Article",
        link: PAGE_URL.NEWS_ARTICLE_HOME_PAGE,
        key: PAGE_URL.NEWS_ARTICLE_HOME_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default newsArticleMenuRegistry;
