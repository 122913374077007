import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const NewsArticle = React.lazy(() =>
    import('../NewsArticle')
);
const NewsArticleAiSummary = React.lazy(() =>
  import('../NewsArticleAiSummary')
);
const NewsArticleAiSummaryPopular = React.lazy(() =>
  import('../NewsArticleAiSummaryPopular')
);
const NewsArticleHome = React.lazy(() =>
  import('../NewsArticleHome')
);
const NewsArticleHomePopular = React.lazy(() =>
  import('../NewsArticleHomePopular')
);
const NewsArticleHomeAll = React.lazy(() =>
  import('../NewsArticleHomeAll')
);
const NewsArticleHomeLegacy = React.lazy(() =>
  import('../NewsArticleHomeLegacy')
);
const NewsArticleHomeCategory = React.lazy(() =>
  import('../NewsArticleHomeCategory')
);
const newsArticleRoutesRegistry = [
    {
        path: PAGE_URL.NEWS_ARTICLE_PAGE,
        element: <NewsArticle />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: PAGE_URL.NEWS_ARTICLE_PAGE + "/ai-summary",
        element: <NewsArticleAiSummary />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: PAGE_URL.NEWS_ARTICLE_PAGE + "/ai-summary-popular",
        element: <NewsArticleAiSummaryPopular />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: PAGE_URL.NEWS_ARTICLE_PAGE + "/home",
        element: <NewsArticleHome />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: PAGE_URL.NEWS_ARTICLE_PAGE + "/home-popular",
        element: <NewsArticleHomePopular />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: PAGE_URL.NEWS_ARTICLE_PAGE + "/home-all",
        element: <NewsArticleHomeAll />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: PAGE_URL.NEWS_ARTICLE_PAGE + "/home-legacy",
        element: <NewsArticleHomeLegacy />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: PAGE_URL.NEWS_ARTICLE_PAGE + "/home-category",
        element: <NewsArticleHomeCategory />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default newsArticleRoutesRegistry;
